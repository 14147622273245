import React, { PureComponent } from 'react';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { IPageContext } from 'src/utils/interfaces';
import Container from '../components/grid/Container/Container';
import Flex from '../components/grid/Flex/Flex';
import Row from '../components/grid/Row/Row';
import Section from '../components/grid/Section/Section';

export class NotFoundPage extends PureComponent<IPageContext> {
  render() {
    return (
      <>
        <CoreLayout title="Page not found">
          <Section>
            <Container>
              <Row>
                <Flex col={6}>Error 404</Flex>
              </Row>
            </Container>
          </Section>
        </CoreLayout>
      </>
    );
  }
}

export default NotFoundPage;
